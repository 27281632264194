import type Splide from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';

const splides: Splide[] = [];

const getElements = () => ({
  storiesSplideElements: document.querySelectorAll<HTMLElement>(
    '.content-block--stories .splide',
  ),
});

export default defineModule(
  async () => {
    const { storiesSplideElements } = getElements();
    if (!storiesSplideElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    storiesSplideElements.forEach((storiesSplideElement) => {
      const splide = new Splide(storiesSplideElement, {
        type: 'loop',
        drag: true,
        clones: 0,
        focus: 'center',
        pagination: false,
        speed: 600,
      }).mount();

      splides.push(splide);
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
