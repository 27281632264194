import type { Splide } from '@splidejs/splide';
import { defineModule } from '@/js/utils/helpers';

const splides: Splide[] = [];

const getElements = () => ({
  locationBlockElements: document.querySelectorAll<HTMLElement>(
    '.content-block--location',
  ),
});

export default defineModule(
  async () => {
    const { locationBlockElements } = getElements();

    if (!locationBlockElements.length) return;

    const { Splide } = await import('@splidejs/splide');

    locationBlockElements.forEach((locationBlockElement) => {
      const carouselElement =
        locationBlockElement.querySelector<HTMLElement>('.splide--main');

      if (!carouselElement) return;

      const mainCarousel = new Splide(carouselElement, {
        type: 'fade',
        rewind: true,
        pagination: false,
        arrows: false,
      });

      const thumbnailElement = locationBlockElement.querySelector<HTMLElement>(
        '.splide--thumbnails',
      );

      if (!thumbnailElement) {
        splides.push(mainCarousel.mount());
        return;
      }

      const thumbnailCarousel = new Splide(thumbnailElement, {
        perPage: 4,
        gap: '1rem',
        rewind: true,
        pagination: false,
        arrows: false,
        isNavigation: true,
      });

      mainCarousel.sync(thumbnailCarousel);
      splides.push(mainCarousel.mount());
      splides.push(thumbnailCarousel.mount());
    });
  },
  () => {
    while (splides.length > 0) {
      splides.pop()?.destroy(true);
    }
  },
);
