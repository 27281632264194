import { BREAKPOINT_MEDIA_QUERIES } from '../../utils/breakpoints';
import { defineModule, nextTick } from '../../utils/helpers';
import { Overlay, hasOpenOverlay, toggleOverlay } from '../../utils/overlays';

const getElements = () => ({
  navbarElement: document.querySelector<HTMLElement>('.navbar'),
  navbarTogglerElement: document.querySelector<HTMLElement>('.navbar__toggler'),
});

export const toggleMenu = (force?: boolean) => {
  const { navbarElement, navbarTogglerElement } = getElements();
  if (!navbarElement || !navbarTogglerElement) return;

  toggleOverlay(
    Overlay.MENU,
    navbarElement.classList.toggle('navbar--open', force),
  );

  navbarTogglerElement.ariaExpanded = `${hasOpenOverlay(Overlay.MENU)}`;
};

const onMenuTogglerClick = () => toggleMenu();

const navbarBreakpointChecker = () => {
  const { navbarElement } = getElements();
  if (!navbarElement) return;

  navbarElement.classList.add('navbar--no-transitions');
  toggleMenu(false);

  nextTick(() => {
    navbarElement.classList.remove('navbar--no-transitions');
  });
};

export default defineModule(
  () => {
    const { navbarTogglerElement } = getElements();
    navbarTogglerElement?.addEventListener('click', onMenuTogglerClick);
    BREAKPOINT_MEDIA_QUERIES.lg.addEventListener(
      'change',
      navbarBreakpointChecker,
      { passive: true },
    );
  },
  () => {
    const { navbarTogglerElement } = getElements();
    navbarTogglerElement?.removeEventListener('click', onMenuTogglerClick);
    BREAKPOINT_MEDIA_QUERIES.lg.removeEventListener(
      'change',
      navbarBreakpointChecker,
    );
  },
);
