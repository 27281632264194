import { isFunction, type defineModule } from '../utils/helpers';
import { toggleMenu } from '../components/menu/toggle';

import Swup from 'swup';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
// @ts-expect-error Package doesn't have types
import SwupGaPlugin from '@swup/ga-plugin';
// @ts-expect-error Package doesn't have types
import SwupGtmPlugin from '@swup/gtm-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupProgressPlugin from '@swup/progress-plugin';
import storiesModule from '../../../Templates/blocks/stories/script';

// Modules
import aosModule from './aos';
import complianzModule from './complianz';

const autoImportedModules = import.meta.glob<ReturnType<typeof defineModule>>(
  ['@/../Templates/blocks/*/script.ts', '../components/**/*.ts'],
  {
    eager: true,
    import: 'default',
  },
);

const modules: ReturnType<typeof defineModule>[] = [
  aosModule,
  complianzModule,
  ...Object.values(autoImportedModules),
];

const setup = () => Promise.all(modules.map((module) => module.setup()));
const cleanup = () =>
  Promise.all(
    modules.map((module) => isFunction(module.cleanup) && module.cleanup()),
  );

const swupNot = '[data-no-swup],[href*="/wp/"],[href*="/app/uploads/"]';
export const swup = new Swup({
  animationSelector: '[class*="swup-transition-"]',
  containers: ['.page-wrapper'],
  plugins: [
    new SwupPreloadPlugin(),
    new SwupBodyClassPlugin(),
    new SwupScrollPlugin({
      getAnchorElement: (hash) => {
        if (hash && hash.charAt(0) === '#') {
          hash = hash.substring(1);
        }

        if (!hash) {
          return null;
        }

        const decoded = decodeURIComponent(hash);
        let element =
          document.getElementById(hash) ||
          document.getElementById(decoded) ||
          document.querySelector(`a[name='${CSS.escape(hash)}']`) ||
          document.querySelector(`a[name='${CSS.escape(decoded)}']`);

        if (!element && hash === 'top') {
          element = document.body;
        }

        toggleMenu(false);

        return element;
      },
      offset: () =>
        (document.querySelector<HTMLElement>('.navbar')?.offsetHeight ?? 0) +
        50,
      animateScroll: {
        betweenPages: false,
        samePage: true,
        samePageWithHash: true,
      },
    }),
    new SwupGaPlugin({ gaMeasurementId: window.gaTrackingId }),
    new SwupGtmPlugin(),
    new SwupScriptsPlugin({
      head: false,
      body: true,
    }),
    new SwupHeadPlugin({
      persistTags: import.meta.env.DEV ? 'style[data-vite-dev-id]' : '',
    }),
    new SwupProgressPlugin(),
  ],
  linkSelector: `a[href^="${window.location.origin}"]:not(${swupNot}), a[href^="/"]:not(${swupNot}), a[href^="#"]:not(${swupNot})`,
});

swup.hooks.on('visit:end', async () => {
  await setup();
});

swup.hooks.on('visit:start', async () => {
  await cleanup();
});

setup();
